// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"

Rails.start()
Turbolinks.start()

var jQuery = require('jquery');
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('bootstrap')
import '../stylesheets/application'
import '@fortawesome/fontawesome-free/css/all.min'

$(document).on('turbolinks:load', function() {
  // do stuff - when dom loaded
})
